<template>
  <div>
    <v-container class="v-container-fluid steppers" fluid>
      <v-row>
        <v-col cols="12" md="12">
          <div>
            <template>
              <v-stepper v-model="step" vertical elevation="0">
                <v-stepper-step color="#151D27" step="1" class="mon-regular">
                  {{ texts.paymentsprocess.titleStep1 }}
                </v-stepper-step>

                <v-stepper-content step="1">
                  <div>
                    <payment-process-warehouse-layout
                      :texts="texts"
                      @sendParam="next"
                      @sendParamWarehouse="selectWarehouse"
                      @sendVendor="sendVendor"
                    ></payment-process-warehouse-layout>
                  </div>
                </v-stepper-content>

                <v-stepper-step color="#151D27" step="2" class="mon-regular">
                  {{ texts.paymentsprocess.titleStep2 }}
                </v-stepper-step>

                <v-stepper-content step="2">
                  <div>
                    <payment-process-payment-method-layout
                      :texts="texts"
                      @sendParam="next"
                      @sendParamPaymentMethod="selectPaymentMethod"
                    ></payment-process-payment-method-layout>
                  </div>
                </v-stepper-content>

                <v-stepper-step color="#151D27" step="3" class="mon-regular">
                  {{ texts.paymentsprocess.titleStep3 }}
                </v-stepper-step>

                <v-stepper-content step="3">
                  <div>
                    <div class="content-card">
                      <div v-if="warehouseConfirmation.length !== 0" class="display-flex align-items-center mb-3">
                        <!-- <div class="ml-2">
                          <p class="text-title mon-medium">{{ warehouseConfirmation.oWarehouse.sName }}</p>
                          <p class="text-location mon-regular">
                            {{ warehouseConfirmation.oLocation.sAddress }} {{ warehouseConfirmation.oLocation.sCity }}
                          </p>
                          <p class="text-location mon-regular">{{ warehouseConfirmation.oWarehouse.sPhoneNumber }}</p>
                        </div> -->
                        <div class="width-100">
                          <p class="text-title mon-medium mb-3">
                            {{ warehouseConfirmation.oWarehouse.sName }}
                          </p>
                          <div class="display-flex align-items-flex-start width-90">
                            <v-icon color="#00000040" size="16px">
                              mdi-map-marker
                            </v-icon>
                            <p class="text-location mon-regular ml-1">
                              {{ warehouseConfirmation.oLocation.sAddress }}
                              {{ warehouseConfirmation.oLocation.sCity }}
                            </p>
                          </div>
                          <div class="display-flex align-items-center width-90">
                            <v-icon color="#00000040" size="16px">
                              mdi-phone
                            </v-icon>
                            <p class="text-location mon-regular ml-1">
                              {{ warehouseConfirmation.oWarehouse.sPhoneNumber }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <GmapMap :center="center" :zoom="4" :options="mapOptions" style="width:100%;  height: 350px;">
                        <GmapMarker :position="{ lat: latSelected, lng: lngSelected }" />
                      </GmapMap>
                    </div>
                    <div v-if="paymentMethodConfirmation.length !== 0" class="content-card display-flex align-items-center">
                      <div class="ml-2">
                        <p class="text-title mon-medium">
                          {{ paymentMethodConfirmation.sName }}
                        </p>
                        <p class="text-location mon-regular">
                          {{ paymentMethodConfirmation.sMessage }}
                        </p>
                      </div>
                    </div>
                    <div class="display-flex align-items-center mt-9 mb-9">
                      <v-icon color="#0089FF" size="20px">
                        mdi-chevron-left
                      </v-icon>
                      <p class="text-return-cart mon-regular" @click="next(2)">
                        {{ texts.paymentsprocess.buttonReturn }}
                      </p>
                      <v-spacer></v-spacer>
                      <v-btn elevation="0" class="button-next mon-bold" @click="dialogConfirmOrder = true">
                        {{ texts.paymentsprocess.buttonFinalized }}
                      </v-btn>
                    </div>
                  </div>
                </v-stepper-content>
              </v-stepper>
            </template>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog persistent v-model="dialogConfirmOrder" :width="screenWidth + '%'">
      <div class="container-dialog">
        <close-dialog-component @closeDialog="closeDialog" />
        <p class="text-title-dialog mon-bold">
          {{ texts.paymentsprocess.modalTextTitle }}
        </p>
        <div class="display-flex align-items-center justify-content-center mb-3">
          <img width="90px" src="@/assets/icons/padlock.png" alt="" />
        </div>
        <div class="display-flex align-items-center justify-content-center">
          <p class="text-description mon-regular" v-html="texts.paymentsprocess.modalTextDescription" />
        </div>
        <div class="display-flex align-items-center justify-content-center">
          <div class="size-checkbox">
            <div class="display-flex align-items-center" style="margin-bottom: -30px;">
              <v-checkbox v-model="acepptTerms"></v-checkbox>
              <p class="text-terms mon-regular">
                {{ texts.paymentsprocess.modalTextTermsConditions1 }}
                <label
                  class="text-link"
                  @click="redirect('https://proplat-public-images.s3.amazonaws.com/PROPLAT-TERMINOS_Y_CONDICIONES.pdf')"
                >
                  {{ texts.paymentsprocess.modalTextTermsConditions2 }}
                </label>
              </p>
            </div>
            <div class="display-flex align-items-center">
              <v-checkbox v-model="acepptPallets"></v-checkbox>
              <p class="text-terms mon-regular" v-html="texts.paymentsprocess.modalTextConditionsPallets" />
            </div>
          </div>
        </div>

        <div class="display-flex align-items-center justify-content-center">
          <div class="size-input-password">
            <v-text-field
              v-model="password"
              :label="texts.paymentsprocess.modalTextPassword"
              :append-icon="bShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (bShowPassword = !bShowPassword)"
              :type="bShowPassword ? 'text' : 'password'"
              outlined
              clearable
              color="#000000"
              dense
              class="global-inputs mon-regular mt-5 "
              :placeholder="texts.paymentsprocess.modalTextPassword"
              persistent-placeholder
            >
            </v-text-field>
          </div>
        </div>
        <div v-if="isResponsive" class="mt-9">
          <v-btn
            class="button-primary mon-bold"
            elevation="0"
            @click="sendInfo"
            :loading="bLoading"
            :disabled="!acepptTerms || !acepptPallets || !password"
            style="width: 100%;"
          >
            {{ texts.paymentsprocess.modalButtonConfirm }}
          </v-btn>
          <v-btn class="button-secondary mon-regular mt-5 mb-2" elevation="0" @click="closeDialog" style="width: 100%;">
            {{ texts.paymentsprocess.modalButtonCancel }}
          </v-btn>
        </div>
        <div v-else class="display-flex align-items-center mt-9">
          <v-btn class="button-cancel mon-regular" elevation="0" @click="closeDialog">
            {{ texts.paymentsprocess.modalButtonCancel }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="button-delete mon-bold ml-5"
            elevation="0"
            @click="sendInfo"
            :loading="bLoading"
            :disabled="!acepptTerms || !acepptPallets || !password"
          >
            {{ texts.paymentsprocess.modalButtonConfirm }}
          </v-btn>
        </div>
      </div>
    </v-dialog>

    <v-dialog persistent v-model="bDialogErrorOrder" width="500px">
      <div class="container-dialog">
        <close-dialog-component @closeDialog="closeDialogErrorOrder" />
        <p class="text-title-dialog mon-bold">
          {{ texts.paymentsprocess.textDialogErrorOrder.sTxtTitle }}
        </p>

        <div class="display-flex align-items-center justify-content-center">
          <p class="text-description mon-regular">
            {{ sMessageErrorOrder }}
          </p>
        </div>

        <div class="display-flex align-items-cente justify-center mt-9">
          <v-btn class="button-cancel mon-regular" elevation="0" @click="setRedirectShoppingCart">
            {{ texts.paymentsprocess.textDialogErrorOrder.sTxtBtn }}
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "PaymentProcessContentLayout",
  props: {
    texts: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      //VARIABLES
      step: 1,
      passToPaymentMethod: false,
      paymentMethodSelected: [],
      warehouseSelected: [],
      dialogConfirmOrder: false,
      screenWidth: 0,
      bLoading: false,
      acepptTerms: false,
      acepptPallets: false,
      password: "",
      bShowPassword: false,
      center: {
        lat: 30.09024,
        lng: -100.712891,
      },
      mapOptions: {
        scrollwheel: false,
        mapTypeControl: false,
        zoomControl: true,
        disableDoubleClickZoom: true,
        keyboardShortcuts: false,
        streetViewControl: false,
        maxZoom: 12,
        minZoom: 3,
      },
      latSelected: 0,
      lngSelected: 0,
      isResponsive: false,
      sVendorIdSelected: "",

      bDialogErrorOrder: false,
      sMessageErrorOrder: "",
    };
  },
  beforeMount() {},
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    redirect: function(url) {
      window.open(url, "blank");
    },
    setRedirectShoppingCart() {
      this.bDialogErrorOrder = false;
      this.$router.push({ name: "ShoppingCart" });
    },
    closeDialog: function() {
      this.dialogConfirmOrder = false;
      this.password = "";
      this.acepptTerms = false;
      this.acepptPallets = false;
    },
    closeDialogErrorOrder() {
      this.bDialogErrorOrder = false;
      this.$router.push({ name: "ShoppingCart" });
    },
    sendInfo: function() {
      this.bLoading = true;
      this.$store
        .dispatch("getVerifyShoppingCart", this.ocheckout.sProviderId)
        .then((respVerifyShoppingCart) => {
          this.$store
            .dispatch("getValidateShoppingGlobal")
            .then((resp) => {
              if (resp) {
                const payload = {
                  sVendorId: this.sVendorIdSelected,
                  sBorderWarehouseId: this.warehouseConfirmation.sBorderWarehouseId,
                  sPaymentMethodId: this.paymentMethodConfirmation.sPaymentMethodId,
                  sPassword: this.password,
                };
                const config = {
                  headers: {
                    Authorization: `Bearer ${this.$store.state.sToken}`,
                  },
                };
                DB.post(`${URI}/api/v1/${this.selectLanguage}/sales-orders`, payload, config)
                  .then((response) => {
                    this.bLoading = false;
                    // this.mixSuccess(response.data.message);
                    // this.$router.push("/order/tab/1");
                  })
                  .catch((error) => {
                    this.bLoading = false;
                    this.mixError(error.response.data.message, error.response.status);
                  });
              } else {
                this.bLoading = false;
                this.closeDialog();
                this.bDialogErrorOrder = true;
                this.sMessageErrorOrder = this.texts.paymentsprocess.textProblemCart;
                // this.mixError(this.texts.paymentsprocess.textProblemCart, 0);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((errorVerifyshoppingCart) => {
          this.bLoading = false;
          this.closeDialog();
          this.bDialogErrorOrder = true;
          this.sMessageErrorOrder = errorVerifyshoppingCart.response.data.message;
        });
    },
    termsCoditions: function() {
      alert("descarga archivos de temrinos y condiciones");
    },
    handleResize: function() {
      if (window.innerWidth >= 960) {
        this.screenWidth = 60;
      } else {
        this.screenWidth = 100;
      }

      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    next: function(e) {
      this.step = e;
    },
    sendVendor: function(value) {
      this.sVendorIdSelected = value;
    },
    selectWarehouse: function(e) {
      this.latSelected = e.oLocation.dLatitude;
      this.lngSelected = e.oLocation.dLongitude;

      this.warehouseSelected = e;
    },
    selectPaymentMethod: function(e) {
      this.paymentMethodSelected = e;
    },
    sFullName: function(e) {
      var matches = e.match(/\b(\w)/g);
      var text = matches.join(""); // JSON
      return text;
    },
  },
  computed: {
    ocheckout() {
      return this.$store.state.ocheckout;
    },
    warehouseConfirmation: function() {
      let arr = this.warehouseSelected;
      return arr;
    },
    selectLanguage: function() {
      return this.$store.state.language;
    },
    paymentMethodConfirmation: function() {
      let arr = this.paymentMethodSelected;
      return arr;
    },
  },
  watch: {},
};
</script>

<style scoped>
.size-checkbox {
  width: 85%;
}

.size-input-password {
  width: 50%;
}

.text-link {
  color: #0971fb;
}

.text-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.button-cancel {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  height: 40px !important;
  text-transform: initial;
  padding-left: 35px !important;
  padding-right: 35px !important;
  width: 45%;
  background-color: #ffffff !important;
}

.button-delete {
  height: 40px !important;
  text-transform: initial;
  background: transparent linear-gradient(264deg, #ffc431 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  padding-left: 35px !important;
  padding-right: 35px !important;
  width: 45%;
}

.text-terms {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #707070;
  margin-bottom: 0px;
}

.container-dialog {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  opacity: 1;
  padding: 25px;
  position: relative;
}

.text-title-dialog {
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-size: 25px;
}

.text-description {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
}

.button-next {
  height: 40px !important;
  background: transparent linear-gradient(264deg, #ffc431 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d !important;
  opacity: 1;
  text-transform: initial;
}

.text-return-cart {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #0089ff;
  opacity: 1;
  margin-bottom: 0px;
}

.text-return-cart:hover {
  text-decoration: underline;
  cursor: pointer;
}

.text-title {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-location {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.3;
  margin-bottom: 0px;
}

.content-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
  padding: 10px;
  margin-bottom: 15px;
  position: relative;
}

.content-logo {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #8bbbdf 0% 0% no-repeat padding-box;
  border-radius: 19px;
  text-align: center;
  font-size: 35px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

@media (max-width: 600px) {
  .container-dialog {
    padding: 10px;
  }

  .size-input-password {
    width: 90%;
  }

  .text-title-dialog {
    font-size: 20px;
  }

  .text-description {
    font-size: 11px;
  }

  .text-terms {
    font-size: 12px;
  }
}
</style>
